<template>
  <div class="operationLogList-wrapper">
    <list ref="list" :searchUrl="searchUrl" :exportUrl="exportUrl" exportPermission="export"
    :searchParams.sync="searchParams" :headers="headers"
    :hasOperateColumn="false">
      <template #searchSlot>
        <v-input label="操作人" v-model="searchParams.inuserName" />
        <v-datepicker
          label="操作时间"
          type="rangedatetimer"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime"
        ></v-datepicker>
        <v-select2 label="操作页面" v-model="searchParams.menuLevel3Id" v-bind="pageParams" :subjoin="pageExtraParams" @onChange="selectedPage"></v-select2>
        <v-select label="按钮事件" v-model="searchParams.menuLevel4Id" :options="eventOps" :disabled="eventLabelDisabled" />
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, getPageListUrl } from './api'

export default {
  name: 'OperationLogList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        startTime: '',
        endTime: '',
        inuserName: '',
        menuLevel3Id: '',
        menuLevel4Id: undefined
      },
      eventOps: [
        {
          text: '全部',
          value: undefined
        }
      ],
      pageParams: {
        searchUrl: getPageListUrl,
        request: {
          text: 'menuName',
          value: 'id'
        },
        response: {
          text: 'menuName',
          value: 'id'
        }
      },
      headers: [
        {
          prop: 'menuLevel0Name',
          label: '一级菜单'
        },
        {
          prop: 'menuLevel1Name',
          label: '二级菜单'
        },
        {
          prop: 'menuLevel2Name',
          label: '三级菜单'
        },
        {
          prop: 'menuLevel3Name',
          label: '四级菜单'
        },
        {
          prop: 'menuLevel4Name',
          label: '五级菜单'
        },
        {
          prop: 'dataObject',
          label: '数据对象'
        },
        {
          prop: 'inuserName',
          label: '操作人'
        },
        {
          prop: 'inuserRole',
          label: '操作人角色'
        },
        {
          prop: 'roleUserType',
          label: '角色类型'
        },
        {
          prop: 'ipAddress',
          label: 'IP地址'
        },
        {
          prop: 'intime',
          label: '操作时间'
        }
      ]
    }
  },
  computed: {
    pageExtraParams() {
      return {
        menuType: '2'
      }
    },
    eventLabelDisabled() {
      return !this.searchParams.menuLevel3Id
    }
  },
  methods: {
    selectedPage() {
      // 每次切换操作页面先清空事件
      this.searchParams.menuLevel4Id = undefined
      if (!!this.searchParams.menuLevel3Id) {
        this.getEventList(this.searchParams.menuLevel3Id)
      }
    },
    // 获取操作事件
    getEventList (id) {
      let data = {
        parentId: id
      }
      this.$axios.get(getPageListUrl, { params: data }).then(res => {
        if (res.status === 100) {
          if (res.data) {
            this.eventOps = [
              {
                text: '全部',
                value: undefined
              }
            ]
            this.eventOps.push(...res.data.map(item => {
              return {
                text: item.menuName,
                value: item.id
              }
            }))
          }
        }
      })
    },
  },
  created () {
    let newTime = new Date()
    let year = newTime.getFullYear()
    let month = newTime.getMonth() + 1 < 10 ? '0' + (newTime.getMonth() + 1) : newTime.getMonth() + 1
    let date = newTime.getDate() < 10 ? '0' + newTime.getDate() : newTime.getDate()
    let hour = newTime.getHours() < 10 ? '0' + newTime.getHours() : newTime.getHours()
    let min = newTime.getMinutes() < 10 ? '0' + newTime.getMinutes() : newTime.getMinutes()

    this.searchParams.startTime = year + '-' + month + '-' + date + ' 00:00'
    this.searchParams.endTime = year + '-' + month + '-' + date + ' ' + hour + ':' + min
  }
}
</script>
